import { Module } from "vuex";
import { getters } from "@/store/productLabels/getters";
import { actions } from "@/store/productLabels/actions";
import { mutations } from "@/store/productLabels/mutations";
import { ProductLabelsState } from "@/store/productLabels/types";
import { RootState } from "@/store/types";
import { pagingDefault } from "../utils";

export const state: ProductLabelsState = {
  productLabels: [],
  pagination: pagingDefault,
  loading: false,
};

const namespaced: boolean = true;

export const productLabels: Module<ProductLabelsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

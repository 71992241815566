import { MutationTree } from "vuex";
import { ProductLabelsState } from "@/store/productLabels/types";

export const mutations: MutationTree<ProductLabelsState> = {
  setProductLabels(state, payload) {
    state.productLabels = payload;
    state.loading = false;
  },

  loading(state) {
    state.loading = true;
  },

  /* Paging */
  paginate(state, payload) {
    state.pagination = payload;
  },
  setTotal(state, payload) {
    state.pagination.totalItems = payload;
  },
};

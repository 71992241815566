import { ActionTree } from "vuex";
import { ProductLabelsState, ProductLabel } from "@/store/productLabels/types";
import { RootState } from "@/store/types";
import { Categories } from "@/api/categories"; // re-using as this should actually be tags

export const actions: ActionTree<ProductLabelsState, RootState> = {
  async fetchProductLabels({ commit }, showDisabled = false) {
    try {
      commit("loading");
      const result = await Categories.fetchCategories(
        undefined,
        -1,
        showDisabled,
        "label"
      );
      commit("setProductLabels", result.data.results);
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async createProductLabel({ commit, dispatch, rootState }, categoryData) {
    try {
      commit("loading");
      categoryData.tagType = { name: "label" };
      categoryData.organisation = { id: rootState.shops.activeShop.id };
      await Categories.createCategory(categoryData);

      dispatch("fetchProductLabels");
      commit(
        "notifications/success",
        `Product Label ${categoryData.name} created`,
        { root: true }
      );
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async updateProductLabel({ commit, dispatch }, label: ProductLabel) {
    try {
      commit("loading");
      await Categories.updateCategory(label);
      dispatch("fetchProductLabels");
      commit("notifications/success", `Product Label ${label.name} updated`, {
        root: true,
      });
    } catch (error) {
      commit("notifications/error", error, { root: true });
    }
  },

  async deleteProductLabel({ commit, dispatch }, label: ProductLabel) {
    try {
      commit("loading");
      await Categories.deleteCategory(label.id);

      dispatch("fetchProductLabels");
      commit("notifications/success", `Product Label ${label.name} deleted`, {
        root: true,
      });
    } catch (error) {
      dispatch(
        "notifications/error",
        { message: "Could not delete product label", error },
        { root: true }
      );
    }
  },

  async disableProductLabel(
    { commit, dispatch },
    data: { label: ProductLabel; showDisabled: boolean }
  ) {
    try {
      commit("loading");
      await Categories.disableCategory(data.label.id, data.label.enabled);
      dispatch("fetchProductLabels", data.showDisabled);
      let msg = data.label.enabled
        ? `Product Label ${data.label.name} disabled`
        : `Product Label ${data.label.name} enabled`;
      commit("notifications/success", msg, { root: true });
    } catch (error) {
      dispatch(
        "notifications/error",
        { message: "Could not delete product label", error },
        { root: true }
      );
    }
  },

  paginate({ commit }, pagination) {
    commit("paginate", pagination);
  },
};

import { store } from "@/store";
import Axios from "axios";
import { generateUrl, generateHeader } from "./http-common";

export class Categories {
  static async fetchCategories(
    page: number | undefined,
    pagesize: number | undefined,
    showDisabled: boolean,
    type = "menu"
  ) {
    return Axios.get(
      generateUrl(
        `${CategoriesUrls.fetchCategoriesUrl}/${store.state.shops.activeShop.id}/type/${type}`
      ),
      {
        params: {
          page,
          pagesize,
          showDisabled,
        },
        headers: await generateHeader(),
      }
    );
  }

  static async createCategory(body: any) {
    return Axios.post(
      generateUrl(`${CategoriesUrls.createCategoryUrl}`),
      {
        ...body,
      },
      { headers: await generateHeader() }
    );
  }

  static async updateCategory(category: any) {
    return Axios.put(
      generateUrl(`product/tag/${category.id}`),
      {
        name: category.name,
        shortDescription: category.shortDescription,
        position: category.position,
      },
      { headers: await generateHeader() }
    );
  }

  static async deleteCategory(id: number) {
    return Axios.delete(generateUrl(`product/tag/${id}`), {
      headers: await generateHeader(),
    });
  }

  static async disableCategory(id: number, disable: boolean) {
    if (disable) {
      return Axios.put(
        generateUrl(`product/tag/${id}/change/disable`),
        {},
        { headers: await generateHeader() }
      );
    } else {
      return Axios.put(
        generateUrl(`product/tag/${id}/change/enable`),
        {},
        { headers: await generateHeader() }
      );
    }
  }
}

namespace CategoriesUrls {
  export const fetchCategoriesUrl = "product/tag/organisation";
  export const createCategoryUrl = "product/tag";
}
